@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.topic-card {
    text-decoration: none;
    color: black;
    font-size: 16px;
    width: unset !important;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        width: 900px !important;
    }

    .card-data-side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-image {
        width: 100%;
        height: auto;

        @include media-breakpoint-up(lg) {
            width: auto;
            height: 100%;
        }
        
    }

    .card-action {
        display: flex;
        justify-content: right;
    }

    .card-content {
        padding: 20px;

        .title {
            font-family: 'Chahuenga-SemiBold';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 30px;
        }

        .description {
            font-family: 'Chahuenga-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 170%;
        }
    }
}