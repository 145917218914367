@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.picture {
    background: linear-gradient(black, black) padding-box,
        linear-gradient(360deg, black, black) border-box;
    border-radius: 50%;
    border: 4px solid transparent;
    height: 153px;
    width: auto;

    @include media-breakpoint-up(md) {
        height: 253px;
    }
}

.textual-content {
    padding-top: 50px;
}

.label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #a5c2d0;
    display: block;
    font-family: 'Chahuenga-Bold';


    @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 30px;
    }
}

.name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    font-family: 'Chahuenga-Bold';

    @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 36px;
    }
}

.email {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    font-family: 'Chahuenga-Bold';

   
   

    @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 36px;
    }
}

.background {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #1e222b;
    font-family: 'Chahuenga-SemiBold';


    @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 30px;
    }
}