@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.archive-page {
    background: #DBEFFF;
    min-height: 100vh;
    
    .page-title {
        font-family: 'Chahuenga-Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 48px;
        text-align: center;
        color: #000000;

        @include media-breakpoint-up(md) {
            font-size: 62px;
            line-height: 78px;

        }
    }

    .page-sub-title {
        font-family: 'Chahuenga-Bold';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 170%;
        text-align: center;
        color: #373737;
        padding: 5px 0 15px 0;

        @include media-breakpoint-up(md) {
            font-size: 32px;
            padding: 10px 0 30px 0;
        }
    }

    .archive-filter-form {
        
        padding-left: 0.5em;
        padding-bottom: 10px;

        .filter-title {
            min-width: 100px;
            font-family: 'Chahuenga-Regular';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
            color: #000000;
        }
    }
    
    .archive-table {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 3px 16px -3px rgba(7, 23, 65, 0.23);
        backdrop-filter: blur(20px);
        border-radius: 5px;
        padding: 20px;

        th {
            padding: 25px;
            font-family: 'Chahuenga-Bold';
            font-size: 16px;

            @include media-breakpoint-up(md) {
                font-size: 20px;
            }
        }

        .added-date-header {
            text-align: right;
        }
    
        table {
            border-spacing: 0;
            text-align: left;
        }
    
        .notification {
            width: 100%;
            text-align: center;
        }
    
        .archive-table-row {
            font-family: 'Chahuenga-SemiBold';
            font-size: 12px;

            @include media-breakpoint-up(md) {
                font-size: 14px;
            }


    
            td {
                padding: 10px 25px;
                border-bottom: 1px solid #D6DDED;
            }
    
            .archive-content-link, .archive-content-link:active {
                color: black;
                text-decoration: none;
            }
        }
    
        .archive-table-row:last-child {
    
            td {
                padding: 10px 25px;
                border-bottom: unset;
            }
        }
    }
}
