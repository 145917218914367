@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.profile-page { 
    background: #DBEFFF;
    min-height: 100vh;

    .profile {
        background: inherit;
        padding: 25px;
    
        .content {
            max-width: 600px;
            padding: 15px;
            margin: auto;
    
            @include media-breakpoint-up(md) {
                padding: 35px;
            }
        }
    }
}