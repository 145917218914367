@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.topic-page {
    background: #DBEFFF;
    min-height: 100vh;
    
    .title {
        font-family: 'Chahuenga-SemiBold';
        text-align: center;
        font-size: 35px;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            font-size: 70px;
        }
    }

    .topic-dropdown-container {
        text-align: center;
    }

    .topic-dropdown {
        width: 100%;
        font-family: 'Chahuenga-SemiBold';

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    .goal {
        font-family: 'Chahuenga-Regular';
        text-align: justify;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            padding-left: 15%;
            padding-right: 15%;
        }
    }
}