@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.footer {
    padding-top: 60px;
    padding-bottom: 25px;
    text-align: center;

    .title {
        font-family: 'Chahuenga-Bold';
        font-size: 24px;
        line-height: 30px;
    }

    .contact {
        font-family: 'Chahuenga-Bold';
        font-size: 20px;
        line-height: 25px;
    } 

    .email {
        font-family: 'Chahuenga-SemiBold';
        font-size: 16px;
    }

    .logo {
        height: 35px;
        width: auto;

        @include media-breakpoint-up(md) {
            height: 50px;
        }
    }
}