@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.segment {
    padding-top: 20px;
    
    .segment-card {
        padding: 10px;
        margin-bottom: 15px;
        width: 100%;
        background-color: rgba(244, 244, 244, 1.0);

        .segment-title {
            font-family: Cahuenga-SemiBoldItalic;
            font-size: 24px;
            font-style: italic;

            @include media-breakpoint-up(md) {
                font-size: 26px;
            }
        }

        .segment-item {
            font-family: Cahuenga-Regular;
            font-size: 14px;
            padding-top: 15px;
            padding-bottom: 25px;
            cursor: pointer;

            a {
                text-decoration: none;
                color: unset;
            }

            @include media-breakpoint-up(md) {
                font-size: 16px;
            }
        }
    }
}