@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.head-line {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .logo {
        width: auto;
        height: 35px;
        cursor: pointer;
    
        @include media-breakpoint-up(md) {
            height: 50px;
        }
    
    }
}

.menu-item {
    font-family: 'Chahuenga-SemiBold';
    font-size: 16px;

    @include media-breakpoint-up(md) {
        font-size: 20px;
    }
}