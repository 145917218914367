@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.home-page {
    font-family: Serif;
    font-size: 14px;
    background: #DBEFFF;
    min-height: 100vh;

    .head-line {

        @include media-breakpoint-up(md) {
            padding-bottom: 10%;
        }

        .welcome {
            font-size: 40px;
            line-height: 41px;
            text-align: center;
            font-family: Chahuenga-SemiBold;
            padding-top: 35px;

            @include media-breakpoint-up(md) {
                font-size: 70px;
                line-height: 80px;
                text-align: left;
            }
        }
        .goal {
            padding-bottom: 30px;
            font-family: Chahuenga-Regular;
            font-size: 18px;
            line-height: 28px;

            @include media-breakpoint-up(md) {
                font-size: 22px;
                line-height: 38px;
            }
        }
    }

    .clusters-title {
        font-family: 'Chahuenga-SemiBold';
        text-align: center;
        font-size: 35px;
        padding-top: 30px;

        @include media-breakpoint-up(md) {
            font-size: 75px;
        }
    }

    .clusters {
        padding-top: 25px;

        .cluster-card {
            margin-top: 20px;
            text-decoration: none;
            color: black;
            font-size: 16px;
            width: unset;
            overflow: hidden;

            .cluster-data-side {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .cluster-image {
                width: 100%;
                height: auto;
            }

            .card-actions {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }

            .card-content {
                padding: 20px;

                .archive-icon {
                    position: absolute;
                    top: 15px;
                    right: 10px;
                }

                .title {
                    font-family: 'Chahuenga-SemiBold';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 30px;
                }

                .description {
                    font-family: 'Chahuenga-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 170%;
                }
            }
        }
    }
}