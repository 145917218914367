@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.about-page {
    background: #DBEFFF;
    min-height: 100vh;

    .page-title {
        font-family: 'Chahuenga-Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 48px;
        text-align: center;
        color: #000000;

        @include media-breakpoint-up(md) {
            font-size: 62px;
            line-height: 78px;

        }
    }

    .cluster-goal {
        font-family: 'Chahuenga-Regular';
        text-align: justify;
        padding-bottom: 20px;
        font-size: 15px;
        line-height: 26px;

        @include media-breakpoint-up(md) {
            font-size: 20px;
            line-height: 32px;
            padding-left: 15%;
            padding-right: 15%;
        }
    }

    .page-sub-title {
        font-family: 'Chahuenga-SemiBold';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 170%;
        text-align: center;
        color: #373737;
        padding: 5px 0 5px 0;

        @include media-breakpoint-up(md) {
            font-size: 42px;
            padding: 10px 0 10px 0;
        }
    }

    .empty-cluster {
        text-align: center;
        font-style: italic;
        padding: 40px 0;
        margin-top: 30px;
        font-size: 18px;

        @include media-breakpoint-up(md) {
            font-size: 25px;
        }
    }

    .subscribe {
        padding: 10px 65px;
    }

    .curator-display {
        max-width: 700px;
        padding: 15px;
        margin: auto;

        @include media-breakpoint-up(md) {
            padding: 35px;
        }
    }

    .topics {
        padding-bottom: 25px;
    }
}