@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.sift-card {
    text-decoration: none;
    color: black;
    font-size: 16px;
    width: unset !important;
    overflow: hidden;

    .card-data-side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-image {
        width: 100%;
        height: auto;
    }

    .card-actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .card-content {
        padding: 20px;

        .archive-icon {
            position: absolute;
            top: 15px;
            right: 10px;
        }

        .title {
            font-family: 'Chahuenga-SemiBold';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 30px;
        }

        .description {
            font-family: 'Chahuenga-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 170%;
        }
    }
}