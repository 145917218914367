@import '/src/style/Variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.user-card {
    padding-top: 10px;


    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 20px;
    }

    .picture {
        background: linear-gradient(black, black) padding-box, linear-gradient(360deg, black, black) border-box;
        border-radius: 50%;
        border: 2px solid transparent;
        height: 50px;
        width: auto;
    }
    
    .user-card-content {
        display: flex;
        gap: 10px;
        justify-content: space-between;

        .user-card-text-content {
            display: flex;
            flex: 2;
            flex-direction: column;
            font-family: Chahuenga-SemiBold;


            .curator {
                font-weight: bold;
                color: #A5C2D0;
            }
            .name {
                font-weight: bold;
                color: black;
                font-size: 20px;
                text-align: left;

            }
        }
    }
}